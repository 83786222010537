import React from "react";
import {Col, Row} from "reactstrap";
import Feature from "../Feature/FeaturesTypeFirst";
import { Fade } from "react-awesome-reveal";
import classes from "./Features.module.css"

const Features2 = (props) => {
    return (
        <React.Fragment>
            <Row className={classes.featuresRow}>
                <Col lg="4" md="4" className={classes.feature}>
                    <Fade top delay={200} triggerOnce={true}>
                        <Feature
                            title={"Know your customers"}
                            description={"Understand your customer base through online sales data. Serve your customers better through enquiries and product support features."}
                        />
                    </Fade>
                </Col>
                <Col lg="4" md="4" className={classes.feature}>
                    <Fade top delay={250} triggerOnce={true}>
                        <Feature
                            title={"B2C & B2B Commerce"}
                            description={"Use price lists to configure prices for B2C and B2B customers. Provide B2B customers a channel to request for quote and customize."}
                        />
                    </Fade>
                </Col>
                <Col lg="4" md="4" className={classes.feature}>
                    <Fade top delay={300} triggerOnce={true}>
                        <Feature
                            title={"Built for med-tech"}
                            description={
                            "Comply with med-tech specific regulations like prescription validation. Built in support for monthly subscriptions, product exchange and combo plans."}
                        />
                    </Fade>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Features2
