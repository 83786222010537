import React from "react";
import './VerticalModal.css';
import Modal from "react-bootstrap/Modal";
import Button from "../../../../../components/Button/Button";

const VerticalModal =(props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={` ${(props.dark) ?  `dark` : ''}`}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Contact Us
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    Contact us at sandeep@attoinnovations.com to learn more.
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default VerticalModal
