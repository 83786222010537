import React, {useState} from "react";
import {Form, Row} from "react-bootstrap";
import {Col} from "reactstrap";
import {EmailIcon, Phone, UserIcon} from "../../../../components/Icons/Icons";
import Button from "../../../../components/Button/Button";
import classes from "./SimpleForm.module.css"
import CustomInput from "../../../../components/CustomInput/CustomInput";
import 'antd/dist/antd.css';

const buttonText = "Send"

async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
}

const SimpleForm = (props) => {

    const [submitted, setSubmitted] = useState(false);


    const onSubmit = (event) => {
        const request = {
            name: document.getElementById('FormInputName').value,
            email: document.getElementById('FormInputEmail').value,
            phone: document.getElementById('FormInputPhone').value
        }

        postData('https://www.doctorsbazaar.com/contactInfo', request).then(r => {
            setSubmitted(true);
        }).catch(error => {
            console.log(error);
            // setSubmitted(true);
        });

        event.preventDefault();
        event.stopPropagation();
    }

    return (
        <React.Fragment>
            {!submitted && <Form className={classes.form} onSubmit={onSubmit}>
                <Form.Row className="align-items-center">
                    <CustomInput
                        classes={`col-lg-3 col-md-12 ${classes.item} ${props.classes}`}
                        controlId="FormInputName"
                        dark={(props.dark == true) ?  true : null}
                        name="name"
                        placeholder="Name"
                        simple={false}
                        leftIcon={true}
                        icon={<UserIcon/>}
                    />
                    <CustomInput
                        classes={`col-lg-3 col-md-12 ${classes.item} ${props.classes}`}
                        controlId="FormInputEmail"
                        type="email"
                        name="name"
                        dark={(props.dark == true) ?  true : null}
                        placeholder="E-mail"
                        simple={false}
                        leftIcon={true}
                        icon={<EmailIcon/>}
                    />
                    <CustomInput
                        classes={`col-lg-3 col-md-12 ${classes.item} ${props.classes}`}
                        controlId="FormInputPhone"
                        type="phone"
                        name="name"
                        dark={(props.dark == true) ?  true : null}
                        placeholder="Phone"
                        simple={false}
                        leftIcon={true}
                        icon={<Phone/>}
                    />

                    <Col lg="3" md="12">
                        <Button
                            type={"submit"}
                            classes={"primary large simple w-100"}
                            value={buttonText}
                        />
                    </Col>
                </Form.Row>
            </Form>}
            {submitted && <div style={{marginTop: '20px'}}>
                <h4 style={{textAlign: 'center'}}>Thank you. We will get back to you shortly.</h4>
            </div>}
        </React.Fragment>
    )
}

export default SimpleForm