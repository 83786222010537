import React from "react";
import {Col, Row} from "reactstrap";
import Feature from "../Feature/FeaturesTypeFirst";
import { Fade } from "react-awesome-reveal";
import classes from "./Features.module.css"

const Features = (props) => {
    return (
        <React.Fragment>
            <Row className={classes.featuresRow}>
                <Col lg="4" md="4" className={classes.feature}>
                    <Fade top delay={200} triggerOnce={true}>
                        <Feature
                            title={"Enable e-commerce"}
                            description={"Open an e-commerce channel for your customers and expand reach using digital marketing."}
                        />
                    </Fade>
                </Col>
                <Col lg="4" md="4" className={classes.feature}>
                    <Fade top delay={250} triggerOnce={true}>
                        <Feature
                            title={"Your brand, your store"}
                            description={"Take control of your e-commerce channel by establishing a direct channel with customers."}
                        />
                    </Fade>
                </Col>
                <Col lg="4" md="4" className={classes.feature}>
                    <Fade top delay={300} triggerOnce={true}>
                        <Feature
                            title={"Single Control Plane"}
                            description={"Manage pricing and product models and promos across the world using a single admin console."}
                        />
                    </Fade>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Features
