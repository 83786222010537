import React from "react";
import {Col, Row} from "reactstrap";
import Feature from "../Feature/FeaturesTypeFirst";
import { Fade } from "react-awesome-reveal";
import classes from "./Features.module.css"

const Features = (props) => {
    return (
        <React.Fragment>
            <Row className={classes.featuresRow}>
                <Col lg="4" md="4" className={classes.feature}>
                    <Fade top delay={200} triggerOnce={true}>
                        <Feature
                            title={"Order Management"}
                            description={"Provide a portal for distributors to submit orders and track shipment."}
                        />
                    </Fade>
                </Col>
                <Col lg="4" md="4" className={classes.feature}>
                    <Fade top delay={250} triggerOnce={true}>
                        <Feature
                            title={"Inventory Management"}
                            description={"Get full visibility into inventory across products and terrtories."}
                        />
                    </Fade>
                </Col>
                <Col lg="4" md="4" className={classes.feature}>
                    <Fade top delay={300} triggerOnce={true}>
                        <Feature
                            title={"Returns Management"}
                            description={"Allow channel partners to return goods either as sales return or for warranty claims."}
                        />
                    </Fade>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Features
