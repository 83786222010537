import React from "react";
import classes from "./FeaturesLayoutsTypeSecond.module.css"

import {Container, Col , Row} from "reactstrap";
import Features from "./Features/Features";

import image from "../../../../img/storefront/riester_store_3.png"
import imageDark from "../../../../img/Safari-dark.png"
import { Fade } from "react-awesome-reveal";


const strongText = 'Case Study'
const title = "Rudolf Riester GmbH";
const description = "A medical diagnostic device manufacturer, it has adopted Storefront to go to market around the world. ";
const description2 = "Leveraging global distribution network and digital marketing, it has embraced the post-covid reality.";

function RiesterStoreFeature (props) {

    return (
        <React.Fragment>
            <section className={`section ${classes.features} ${(props.dark) ?  `dark ${classes.dark}` : ''}`}>
                <div className={classes.gridWrapper}>
                    <Container>
                        <Row>
                            <Col lg="6" md="7" className={classes.leftColumn}>
                                <Fade top delay={50} triggerOnce={true}>
                                    <strong  style={{display: 'block'}}>{strongText}</strong>
                                </Fade>

                                <Fade top triggerOnce={true}>
                                    <h2 className={`title ${classes.title}`}>{title}</h2>
                                </Fade>

                                <Fade top delay={50} triggerOnce={true}>
                                    <p className={`normal-text ${classes.description}`}>{description}</p>
                                </Fade>
                                <Fade top delay={50} triggerOnce={true}>
                                    <p className={`normal-text ${classes.description}`}>{description2}</p>
                                </Fade>

                                <Features
                                    dark={props.dark ? true : false}
                                />
                            </Col>

                            <Col lg="6" md="5" className={classes.rightColumn}>
                                <Fade  delay={50} triggerOnce={true}>
                                    {props.dark == true ?
                                        <>
                                            <img
                                                src={imageDark}
                                                alt=""
                                                width="990"
                                                height="610"
                                            />
                                        </>
                                        :
                                        <>
                                            <img
                                                src={image}
                                                alt=""
                                                width="990"
                                                height="610"
                                            />
                                        </>
                                    }
                                </Fade>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </React.Fragment>
    )
}
export default RiesterStoreFeature;
