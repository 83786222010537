import HeroLayoutsTypeNinth from "./Hero/HeroLayoutsTypeNinth";
import React from "react";
import Footer from "../Footer/FootersLayoutsTypeThird";
import MedtronicStoreFeature from "./MedtronicStoreFeature/FeaturesLayoutsTypeSecond";
import RiesterStoreFeature from "./RiesterStoreFeature/FeaturesLayoutsTypeSecond";
import StorefrontPageFeatures from "./Features/FeaturesLayoutsTypeTenth";
import ContactUsForm from "../ContactUsForm/FormsLayoutsTypeFirst";

const StorefrontPage = () => {
    return <div>
        <HeroLayoutsTypeNinth/>
        <MedtronicStoreFeature/>
        <RiesterStoreFeature/>
        <StorefrontPageFeatures/>
        <ContactUsForm />
        <Footer />
    </div>
}

export default StorefrontPage;
