import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import classes from "./Features.module.css"

import Oval from "../../../../../components/Frame/Frame";
import {Location, Network, Support, User, VisibilityOn} from "../../../../../components/Icons/Icons";
import {Fade} from "react-awesome-reveal";

const Features = (props) => {
    return (
        <section className={`${classes.feature}  ${(props.dark) ?  `dark ${classes.dark}` : ''}`}>
            <ListGroup className={classes.listGroup}>
                    <ListGroup.Item className={classes.item}>
                        <Fade top delay={100} triggerOnce={true}>
                        <Oval variant="large">
                            <Location/>
                        </Oval>
                        <p className="text">Geography based order routing</p>
                        </Fade>
                    </ListGroup.Item>
                <ListGroup.Item className={classes.item}>
                    <Fade top delay={150} triggerOnce={true}>
                    <Oval variant="large">
                        <Network/>
                    </Oval>
                    <p className="text">International rollout</p>
                    </Fade>
                </ListGroup.Item>
                <ListGroup.Item className={classes.item}>
                    <Fade top delay={200} triggerOnce={true}>
                    <Oval variant="large">
                        <Support/>
                    </Oval>
                    <p className="text">Enquiries and customer support routed to distributors</p>
                    </Fade>
                </ListGroup.Item>
                <ListGroup.Item className={classes.item}>
                    <Fade top delay={200} triggerOnce={true}>
                    <Oval variant="large">
                        <VisibilityOn/>
                    </Oval>
                    <p className="text">Visibility into distributor inventory</p>
                    </Fade>
                </ListGroup.Item>
                <ListGroup.Item className={classes.item}>
                    <Fade top delay={200} triggerOnce={true}>
                    <Oval variant="large">
                        <User/>
                    </Oval>
                    <p className="text">Know your customers</p>
                    </Fade>
                </ListGroup.Item>
            </ListGroup>
        </section>
    )
}

export default Features