import HeroLayoutsTypeNinth from "./Hero/HeroLayoutsTypeNinth";
import FeaturesLayoutsTypeFirst from "./StorefrontFeature/FeaturesLayoutsTypeFirst";
import DistributorCollaborationFeature from "./DistributorCollaboration/DistributorCollaborationFeature";
import Footer from "../Footer/FootersLayoutsTypeThird";
import React from "react";
import ContactUsForm from "../ContactUsForm/FormsLayoutsTypeFirst";

const HomePage = () => {
    return <div>
        <HeroLayoutsTypeNinth/>
        <FeaturesLayoutsTypeFirst/>
        <DistributorCollaborationFeature />
        <ContactUsForm />
        {/*<Testimonials/>*/}
        <Footer />
    </div>
}

export default HomePage;