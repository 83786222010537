import React from "react";
import {Col, Row} from "reactstrap";
import Feature from "../Feature/FeaturesTypeEighth";
import {Basket, Bookmark, Extension, Group, Home, Star} from "../../../../../components/Icons/Icons";
import Oval from "../../../../../components/Frame/Frame";
import {Fade} from "react-awesome-reveal";
import classes from "./Features.module.css"

const Features = (props) => {
    return (
        <React.Fragment>
            <Row className={classes.featuresRow}>
                <Col lg="4" md="6" sm="6" className={classes.item}>
                    <Fade top delay={100} triggerOnce={true}>
                        <Feature
                            title={"Distributor Driven"}
                            description={"Collaborative Ecommerce that takes your distributors with you."}
                        >
                            <Oval variant={"large"}>
                                <Bookmark/>
                            </Oval>
                        </Feature>
                    </Fade>
                </Col>
                <Col lg="4" md="6" sm="6" className={classes.item}>
                    <Fade top delay={150} triggerOnce={true}>
                        <Feature
                            title={"Single Platform"}
                            description={"Enable global ecommerce on a single platform."}
                        >
                            <Oval variant={"large"}>
                                <Home/>
                            </Oval>

                        </Feature>
                    </Fade>
                </Col>
                <Col lg="4" md="6" sm="6" className={classes.item}>
                    <Fade top delay={200} triggerOnce={true}>
                        <Feature
                            title={"Know Your Customers"}
                            description={"Learn about your customer needs in different geographies."}
                        >
                            <Oval variant={"large"}>
                                <Basket/>
                            </Oval>
                        </Feature>
                    </Fade>
                </Col>
                <Col lg="4" md="6" sm="6" className={classes.item}>
                    <Fade top delay={250} triggerOnce={true}>
                        <Feature
                            title={"Customizable"}
                            description={"We support custom requirements to meet your unique needs."}
                        >
                            <Oval variant={"large"}>
                                <Extension/>
                            </Oval>
                        </Feature>
                    </Fade>
                </Col>
                <Col lg="4" md="6" sm="6" className={classes.item}>
                    <Fade top delay={300} triggerOnce={true}>
                        <Feature
                            title={"Org Structure"}
                            description={"Control access to your team based on territory and product category."}
                        >
                            <Oval variant={"large"}>
                                <Group/>
                            </Oval>
                        </Feature>
                    </Fade>
                </Col>
                <Col lg="4" md="6" sm="6" className={classes.item}>
                    <Fade top delay={400} triggerOnce={true}>
                        <Feature
                            title={"Managed Services"}
                            description={"Offload store, product content and price list management to our team."}
                        >
                            <Oval variant={"large"}>
                                <Star/>
                            </Oval>
                        </Feature>
                    </Fade>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Features