import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import HomePage from "./layouts/Website/HomePage/HomePage";
import StorefrontPage from "./layouts/Website/Storefront/StorefrontPage";

function App() {
return (
    <Router>
        <div>
            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Switch>
                <Route path="/storefront">
                    <StorefrontPage />
                </Route>
                {/*<Route path="/users">*/}
                {/*    <Users />*/}
                {/*</Route>*/}
                <Route path="/">
                    <HomePage />
                </Route>
            </Switch>
        </div>
    </Router>
);
}


export default App;
