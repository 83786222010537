import React from "react";
import {Col, Row} from "reactstrap";
import Feature from "../Feature/FeaturesTypeFirst";
import { Fade } from "react-awesome-reveal";
import classes from "./Features.module.css"

const Features2 = (props) => {
    return (
        <React.Fragment>
            <Row className={classes.featuresRow}>
                <Col lg="4" md="4" className={classes.feature}>
                    <Fade top delay={200} triggerOnce={true}>
                        <Feature
                            title={"Credit Management"}
                            description={"Manage credit notes and debit notes including requests and approval process online. Update payment status."}
                        />
                    </Fade>
                </Col>
                <Col lg="4" md="4" className={classes.feature}>
                    <Fade top delay={250} triggerOnce={true}>
                        <Feature
                            title={"Content Management"}
                            description={"Publish training content, brochures, videos in one accessible place."}
                        />
                    </Fade>
                </Col>
                <Col lg="4" md="4" className={classes.feature}>
                    <Fade top delay={300} triggerOnce={true}>
                        <Feature
                            title={"Distributor Performance"}
                            description={
                            "Collaborate with distributors on leads, incentivise product categories using price controls."}
                        />
                    </Fade>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Features2
